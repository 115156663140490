<template>
    <v-container class="white" grid-list-lg>
        <v-card slot="leftContainer">
            <v-card-title class="secondaryTheme--text">
                <h5>
                    <b>Seleccionar la fecha de la CAJA a visualizar</b>
                </h5>
                <v-spacer></v-spacer>
                <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="fecha"
                            label="Seleccionar Fecha"
                            prepend-icon="mdi-calendar"
                            readonly
                            data-vv-name="fecha"
                            data-vv-as="fecha"
                            :error-messages="errors.collect('fecha')"
                            v-on="on"
                        />
                    </template>
                    <v-date-picker v-model="fecha" no-title locale="es" @input="menu = false" />
                </v-menu>
            </v-card-title>
        </v-card>
        <v-card slot="rightContainer" text>
            <v-row dense>
                <v-col cols="12" class="elevation-1" v-if="!loading">
                    <v-card-title class="grey lighten-3">
                        <h5>Resumen Total del día {{ fecha | date }}</h5>
                    </v-card-title>
                    <DetalleCaja :items="cajaCierreDia" :itemsColaborador="cajaCierreColaboradorDia"></DetalleCaja>
                </v-col>
                <v-col cols="12" class="elevation-1" v-else>
                    <v-progress-linear indeterminate value="15"></v-progress-linear>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>
<script>
import { format, parseISO } from 'date-fns'
import { mapState } from 'vuex'

import DetalleCaja from '@/components/caja/DetalleCaja'

export default {
    $_veeValidate: {
        validator: 'new'
    },
    filters: {
        date(val) {
            return format(parseISO(val), 'dd/MM/yyyy')
        }
    },
    data() {
        return {
            menu: false,
            fecha: format(new Date(), 'yyyy-MM-dd'),
            loading: false
        }
    },
    computed: {
        ...mapState('caja', ['cajaInfo', 'cajaCierreDia', 'cajaCierreColaboradorDia', 'cajaUser']),
        ...mapState('ventas', ['listaPreReservaToday']),
        ...mapState('users', ['user'])
    },
    components: {
        DetalleCaja
    },
    watch: {
        async fecha(val) {
            // //console.log("fecha selec : " + val);
            await this.getAllCaja(val)
        }
    },
    async created() {
        await this.getAllCaja()
    },
    methods: {
        async getAllCaja(fecha) {
            this.loading = true
            console.log('caja')
            await this.$store.dispatch('caja/getCajaTotalDia', { fecha, typeDate: 1 })
            this.loading = false
            //await this.$store.dispatch('caja/getCajaTotalUser', { fecha })
        }
    }
}
</script>
